import React from "react";

export interface ProjectDetails {
    image: string,
    title: string,
    description: string,
    skills: string[],
    links: LinkDetails[],
    webPath: string,
}

interface LinkDetails {
    image: string,
    link: string,
}

export default function ProjectContainer({ image, title, description, skills, links, webPath }: ProjectDetails) {
    return (
        <div className="mx-auto bg-contrast text-alt p-5 max-w-[300px] sm:min-h-[500px] flex flex-col" key={`project-${title}`}>
            {/* <a href={webPath} className="flex flex-col sm:flex-grow"> */}
                <div className="flex flex-col items-center sm:flex-grow">
                    <img className="rounded-xl w-auto h-auto sm:w-32 mb-2" src={image} alt={title} />
                    <h1 className="text-2xl text-primary p-2 font-bold mb-2 flex-shrink-0">{title}</h1>
                    <p className="text-primary text-sm mb-2 flex-grow">{description}</p>
                    <div className="flex flex-row flex-wrap justify-center p-5 mb-2 flex-shrink-0">
                        {skills.map(skill => (
                            <p className="p-2 text-primary font-bold text-xs">{skill}</p>
                        ))}
                    </div>
                    <div className="flex flex-row space-x-3 mx-auto pb-5 mb-2 flex-shrink-0">
                        {
                            links.map(link => (
                                <a key={link.link} href={link.link} target="_blank" rel="noopener noreferrer"  className="text-primary transition ease-in delay-150 hover:-translate-y-1 hover:text-white"><i className={`${link.image}`} /></a>
                            ))
                        }
                    </div>
                </div>
            {/* </a> */}
            {/* <div className="text-primary bg-main px-3 py-2 text-sm mx-auto w-1/2 drop-shadow-md transition ease-in delay-150 hover:text-white hover:-translate-y-1 mt-auto">Learn More</div> */}
        </div>
    );
}
