import './App.css';
import Navbar from './component/Navbar/navbar.tsx'
import Home from './pages/home.tsx'

function App() {
  return (
    <>
    <Navbar />
    <Home/>
    </>
  );
}

export default App;
