import React from 'react'
import ProjectContainer, { ProjectDetails } from '../component/Project/projectContainer.tsx'
import Skill from '../component/Project/skillContainer.tsx'

const projects : ProjectDetails[] = [
    {
        image: "/projects/vrb_main.jpg",
        title: "Virtual Round Ballers",
        description: "As a Gameplay and AI Programmer, I guided the project from prototype to release, focusing on gameplay mechanics and AI development.",
        skills: ["C++", "UE5", "VR", "Gameplay", "AI"],
        links: [
            {
                image: "fas fa-external-link-alt",
                link: "https://www.virtualroundballers.com"
            }
        ],
        webPath: '/projects/vrb'
    },
    {
      image: "/projects/virtual_trades.png",
      title: "Virtual Trades",
      description: "Lead Developer for the conversion from Native Oculus to WebXR, refactored the codebase for increased modularity and scalability.",
      skills: ["C#", "Unity", "WebXR", "VR", "Simulation"],
      links: [
          {
            image: "fas fa-vr-cardboard fa-lg",
            link: "https://industrylift-bcbb2.web.app"
          }
      ],
      webPath: '/projects/virtual-trades'
  },
  {
    image: "/projects/umbilical.png",
    title: "Umbilical",
    description: 'As the solo developer, I took the game from initial idea to completion, emphasizing innovative 2.5D platformer mechanics and cooperative puzzle-solving dynamics.',
    skills: ["C#", "Unity", "Physics Movement", "3D Art", "Solo Dev"],
    links: [
        {
          image: "fas fa-external-link-alt",
          link: "https://inexperienced-developer.itch.io/umbilical"
        }
    ],
    webPath: '/projects/umbilical'
  },
  {
      image: "/projects/fast-asleep.png",
      title: "Fast Asleep",
      description: "A personal project I am producing, designing, and developing in Unreal Engine",
      skills: ["C++", "UE5", "Solo Dev"],
      links: [
          {
              image: "fab fa-github fa-lg",
              link: "https://github.com/inexperienced-developer/FastAsleep"
          },
          {
              image: "fas fa-file fa-lg",
              link: "/docs/Fast_Asleep_GDD.pdf"
          },
      ],
      webPath: '/projects/fast-asleep'
  }
]

const skills : string[] = [
  "C++",
  "C#",
  "Python",
  "Javascript",
  "Golang",
  "Game Production",
  "Gameplay Programming",
  "AI Programming",
  "Machine Learning",
  "Unreal Engine 4/5",
  "Unity",
  "Blender",
]

function Jacob() {
  return(
<div className='flex flex-col items-center pl-10 pr-10 pt-16'>
  {/* img */}
  <div className="sm:p-10 min-h-fit">
    <img
      className='rounded-3xl w-[300px] h-auto sm:w-auto max-h-[350px]' 
      src='/profile_pic3.png'
      alt="me"
    />
  </div>
  <div className=" text-center pt-5">
    <h1 className='text-3xl sm:text-6xl'>Hey! I'm <span className='text-secondary'>Jacob</span>!👋</h1>
    <h1 className='text-xl sm:text-4xl'>I craft worlds one pixel at a time.</h1>
  </div>
  <div className="flex flex-row space-x-4 items-center p-5">
    <button onClick={() => window.open('/docs/JacobBermanResume.pdf', '_blank')}
      className="h-8 px-5 m-2  bg-transparent transition ease-in duration-300  ring-1 ring-white hover:bg-white hover:text-gray-800 focus:shadow-outline">
        Resume
    </button>
    <a href="https://www.youtube.com/@InexperiencedDeveloper/" target="_blank" rel="noopener noreferrer" className="opacity-50 hover:opacity-100 hover:underline ease-in duration-300"><img width="32" src="/YT/yt_icon_mono_dark.png" alt="" /></a>
    <a href="https://github.com/inexperienced-developer/" target="_blank" rel="noopener noreferrer" className="opacity-50 hover:opacity-100 hover:underline ease-in duration-300"><img width="32" src="/github/github-mark-white.svg" alt="" /></a>
    <a href="https://www.linkedin.com/in/jacob-berman-5b014098/" target="_blank" rel="noopener noreferrer" className="opacity-50 hover:opacity-100 hover:underline ease-in duration-300"><img width="32" src="/LinkedIn-Logos/LI-In-white.png" alt="" /></a>
    <a href="https://inexperienced-developer.itch.io/" target="_blank" rel="noopener noreferrer" className="opacity-50 hover:opacity-100 hover:underline ease-in duration-300"><img width="32" src="/itch/itchio-textless-white.svg" alt="" /></a>
  </div>
  <AboutMe />
</div>
  )
}

function AboutMe() {
  return(
    <div className='flex flex-col text-center items-center pl-10 pr-10 pb-5'>
      <h1 className='text-3xl sm:text-4xl pt-3'>I have a <span className="text-secondary">YouTube</span> channel</h1>
      <h1 className='text-3xl sm:text-4xl pt-3'>Where I teach Game Development</h1>
      <iframe className="pt-10 w-[300px] sm:w-[560px]" width="560" height="315" src="https://www.youtube.com/embed/M0JTCm0DC1A?si=eVwiSI_Vt0-rKfvQ" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
    </div>
  )
}

function Companies() {
  return(
    <div className='flex flex-col text-center items-center pl-10 pr-10 pb-5'>
    <h1 className='text-3xl sm:text-4xl pt-3'>Some <span className="text-secondary">companies</span> I have supported</h1>
    <div className='flex flex-col items-center space-y-4 pl-10 pt-5 pr-10 pb-5 sm:flex-row sm:space-x-4 sm:space-y-0'>
      <img 
        src="/clients/dod.png"
        alt=''
        width={100}
        height={100}
      />
      <img 
        src="/clients/zeltech.png"
        alt=''
        width={150}
        height={150}
      />
      <img 
        src="/clients/DuPont.png"
        alt=''
        width={150}
        height={150}
      />
      <img 
        src="/clients/jf.png"
        alt=''
        width={100}
        height={150}
      />
    </div>
  </div>
  )
}

function Project() {
  return(
    <div className='flex flex-col text-center items-center pl-10 pr-10 pb-5' id="projects">
      <h1 className='text-3xl sm:text-4xl pt-3 pb-14 '>Here are some <span className="text-secondary">projects</span> I've worked on</h1>
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-10 sm:max-w-[800px] sm:justify-center">
        {
            projects.map(project => (
                <ProjectContainer key={project.title} {...project}/>
            ))
        }
      </div>
    </div>
  )
}

function Skills() {
  return(
    <div className='flex flex-col text-center items-center pl-10 pr-10 pb-5' id="skills">
      <h1 className='text-3xl sm:text-4xl pt-3'>Some <span className="text-secondary">skills</span> I have include</h1>
      <div className="flex flex-row flex-wrap justify-center p-5 mb-2 flex-shrink-0 space-x-3 sm:pt-10 items-center max-w-[450px]">
        {
          skills.map((skill) => (
            <Skill text={skill}/>
          ))
        }
      </div>
  </div>
  )
}

function Contact() {
  return(
    <div className='flex flex-col text-center items-center pl-10 pr-10 pb-5' id="contact">
      <h1 className='text-3xl sm:text-4xl pt-3 pb-10'>I'm always available so <span className="text-secondary">reach out</span> if you need anything</h1>
      <button 
  onClick={() => window.open('mailto:jacobberman1234@gmail.com', '_blank')} 
  className="h-[75px] w-[200px] bg-btn transition ease-in duration-300 rounded-md text-xl focus:shadow-outline"
>
  <span className="fa-lg fa fa-envelope"></span> Contact
</button>
    </div>
  )
}

export default function Home() {
  return (
    <div className="flex flex-col container items-center justify-center w-full mx-auto">
      <Jacob />
      <Project />
      <Companies />
      <Skills />
      <Contact />
    </div>
  )
}
