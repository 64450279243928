import React from 'react'
import { Fragment, useEffect, useState } from 'react'
import { Disclosure } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'

const menuItems = [
  { name: 'Projects', link: '#projects' },
  { name: 'Skills', link: '#skills' },
  { name: 'Contact', link: '#contact' }
]

export default function Navbar() {
  const [isScrolled, setIsScrolled] = useState<boolean>(false);

  useEffect(() => {
    const handleScroll = () => {
      const yOffset = window.scrollY;
      const scrollTop = document.documentElement.scrollTop
      if (yOffset > 20 || scrollTop > 20) {
        setIsScrolled(true)
      } else {
        setIsScrolled(false)
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <Disclosure as="nav" className={`fixed bg-main w-screen z-50 ${isScrolled ? 'shadow-md' : ''}`}>
      {({ open }) => (
        <>
          <div className="container mx-auto">
            <div className="flex justify-between justify-items-center pl-5 pr-5 h-10 pt-2 sm:h-14 sm:justify-center sm:items-center">
              <a href="/" className="sm:pr-10">Jacob Berman</a>
              <div className="">
              <div className="hidden sm:block">
                <div className="flex space-x-4">
                  {menuItems.map(item => (
                    <a
                      key={item.name}
                      href={item.link}
                      className={item.name === 'Projects'
                        ? "rounded-md px-3 text-sm font-medium text-primary hover:text-white"
                        : "rounded-md px-3 text-sm font-medium hover:text-white"
                      }
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
                </div>
                <Disclosure.Button className="flex sm:hidden">
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>
            <Disclosure.Panel className="sm:hidden space-y-1 px-2 pb-3 pt-2">
             {menuItems.map(item => (
               <Disclosure.Button
                 key={item.name}
                 as="a"
                 href={item.link}
                 className="block rounded-md px-3 py-2 text-base font-medium text-primary hover:text-white"
               >
                 {item.name}
               </Disclosure.Button>
             ))}
           </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}
